.pagos .precios {
    margin: 15px 0px;
    border: 1px solid transparent;
    padding: 15px;
    position: relative;
    border-radius: 4px;
    box-shadow: 0 0 0 2px rgba(227, 123, 0, 0.2);
}

.pagos .precios h4 {
    position: absolute;
    top: -16px; 
    left: 10px;
    background-color: white;
}

.precio-area .contenedor {
    margin: 3px 0px 20px 0px !important;
    padding: 5px 10px;
    box-shadow: 1px 1px 7px 1px #0000001c;
    border-radius: 4px;
}
.precio-area .contenedor .centrado {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.precio-area-prodcuto {
    /* display: flex;
    flex-direction: row;
    justify-content: space-between; */
    margin: 2px 20px !important;
    /* border-bottom: 1px solid;
    padding: 1px 5px;
    padding-bottom: 4px; */
}
.precio-area-prodcuto div {
    display: flex;
    align-items: center;
}
.precio-area table {
    width: 100%;
    /* padding: 10px; */
    /* margin: 1px 15px; */
}
.precio-area table thead td {
    font-weight: bolder;
    font-size: 10px;
    text-align: center;
}
.precio-area table tbody td {
    font-size: 14px;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.205);
    padding: 5px 0px;
}