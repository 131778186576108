/* .butacas .butaca {
    width: 100px;
    height: 100px;
    background-color: rgb(189, 189, 189);
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.2s, border-radius 0.5s;
}
.butacas .butaca:hover {
    background-color: rgb(212, 211, 211);
    border-radius: 15px;
}
.butacas .butaca.selected {
    background-color: rgb(47, 97, 212);
    border-radius: 5px;
} */

.butacas {
    text-align: center;
}
/* .butacas svg {
    width: 100%;
    min-height: 90vh;
} */
svg.butaca {
    margin: 1px;
}
path.butaca {
    fill: rgb(189, 189, 189);
    cursor: pointer;
    transition: fill 0.1s;
}
path.butaca:hover {
    fill: rgb(141, 141, 141);
    cursor: pointer;
}

path.butaca.muerta {
    fill: rgb(15, 15, 15);
}
path.butaca.muerta:hover {
    fill: rgb(31, 31, 31);
}

path.butaca.esButaca {
    fill: rgb(246, 160, 0);
}
path.butaca.esButaca:hover {
    fill: rgb(221, 152, 24);
}

path.butaca.selected {
    fill: rgb(0, 140, 255);
}
path.butaca.selected:hover {
    fill: rgb(27, 128, 211);
}
