.disponibilidad .programacion {
    padding: 10px;
    border: 1px solid;
    margin: 1px 5px;
    border-radius: 4px;
}
.disponibilidad .prog {
    margin: 10px 2px !important;
}

.distribucion table {
    width: 100%;
}
.distribucion thead td {
    font-weight: bold;
}
.distribucion td {
    border-bottom: 1px solid #0000001a;
    padding: 2px 0px;
}