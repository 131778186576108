[class^="col"] {
  padding: 2px !important;
}

.error-message,
.warning-message {
  margin: 8px 14px 0;
  font-size: 0.6rem;
  min-height: 1em;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1 !important;
  letter-spacing: 0.03333em;
  position: absolute;
  z-index: 100;
  /* top: -10px; */
  /* left: 1px; */
  bottom: -3px;
  right: 10px;
  text-align: right;
  opacity: 0.9;
  background-color: white;
  padding: 0px 1px;
  animation: message-in 0.5s forwards;
}

.error-message {
  color: #c04040;
}

.warning-message {
  color: #db8126;
}

@keyframes message-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.form-field {
  position: relative;
  margin: 4px auto;
  /* margin: 3px auto; */
}

.col-form label.form-label {
  position: absolute;
  z-index: 100;
  top: -8px;
  background-color: white;
  left: 8px;
  margin: 0px !important;
  padding-left: 5px;
  padding: 0px;
  line-height: 1;
  font-size: 13px;
  /* opacity: 0.7; */
}

.form-field-invalid {
  border: 1px solid;
  border-color: transparent;
  border-radius: 5px;
  animation: error-border-in 0.5s forwards;
}

.ant-radio-group, .ant-checkbox-wrapper {
  padding: 8px !important;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.radio-label {
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: white;
    color: rgb(197, 191, 191);
}

@keyframes error-border-in {
  from {
    border-color: transparent;
  }
  to {
    border-color: #c0404055;
  }
}

.section h3 {
  font-size: 1.2rem;
  margin: 6px 0px 1px 0px;
}

.id {
  text-align: right;
}
.id span {
  border-radius: 10px;
  border: 1px solid;
  padding: 1px 10px;
}

/* .ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  /* display: block !important; *
} */

.input-color .color {
  width: 100%;
  height: 34px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #d9d9d9;
}
.input-color input {
  width: 100%;
  border: 0px solid transparent;
  padding-left: 5px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  height: 34px;
  outline: none;
}
.input-color .contenedor {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  height: 40px;
  transition: box-shadow 0.2s;
}
.input-color .contenedor.focus {
  border: 1px solid #f09c26;
  box-shadow: 0 0 0 2px rgba(227, 123, 0, 0.2);
}

/* -------------------- FORM FIELDS */
.cform-field {
  padding: 5px 0px;
}
.cform-field p.title {
  margin: 0;
  font-weight: bold;
}

.aviso-estado {
  background-color: #e30000;
  color: white;
  padding: 6px 10px;
  border-radius: 10px;
}
.aviso-estado.son {
  background-color: #cecece;
  color: black;
}

span.caption {
  color: #f09c26;
}