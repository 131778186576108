
.row {
  margin: 0 !important;
}

.anticon {
  vertical-align: 2px !important; 
}

.ant-modal-body {
  overflow-y: auto;
  overflow: auto !important;
}