/* .multiplicar .fechas {

} */
.multiplicar .fecha {
    border: 1px solid;
    border-radius: 4px;
    padding: 10px;
    padding-bottom: 0px;
    margin-top: 20px;
    margin-bottom: 15px;
    position: relative;
}

.multiplicar span.numero {
    position: absolute;
    top: -15px;
    line-height: 1;
    margin: 0px;
    right: 10px;
    /* font-weight: bold; */
    font-size: 20px;
    background-color: white;
    border: 1px solid;
    border-radius: 50%;
    padding: 0px 8px 5px 8px;
    cursor: pointer;
}