.apertura .estado-con {
    position: relative;
    border: 1px solid;
    border-radius: 5px;
    padding: 15px 15px;
    margin: 20px 0px;
}
.apertura .estado-con span.estado-tit {
    position: absolute;
    top: -13px;
    left: 20px;
    background-color: white;
    text-transform: uppercase;
}
.apertura .estado-con h2 {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 3px;
    margin: 0px;
}

.bloque-dato {
    position: relative;
    border: 1px solid grey;
    border-radius: 5px;
    margin: 10px;
    width: fit-content;
    padding: 10px;
    min-width: 170px;
    text-align: center;
}
.bloque-dato .bloque-dato-tit {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: -10px;
    background-color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
}